import React from "react"
import Layout from "../layouts/es"
import Link from "gatsby-link"
import { Check } from "react-feather"
import SEO from "../components/seo"

const IndexPage = (props) => {
    return (
        <Layout location={props.location}>
            <div>
                <SEO title="Firma electrónica manuscrita biométrica"
                     description="Firma electrónicamente documentos jurídicamente vinculantes desde cualquier dispositivo y lugar. Fácil, rápido y legal." />

                <header className="masthead">
                    <div className="container h-100">
                        <div className="row h-100 align-items-center">
                            <div className="col-xl-5 text-left">
                                <h1 className="spacing mb-4 display-4">Firma documentos desde cualquier dispositivo y en cualquier lugar de forma rápida y sencilla</h1>
                                <p className="spacing mb-4"> Con Tecalis Signature podrás agilizar los procesos de tu negocio gracias a su diseño sencillo y vanguardista.</p>
                                <p>
                                    <a data-scroll href="#moreinfo" className="btn btn-tecalis mr-4 spacing">Conocer más</a>
                                    <a href="" data-toggle="modal" data-target="#contacto" className="ventas spacing block-btn">Contactar con ventas 👉</a>
                                </p>
                            </div>
                            <div className="col-xl-7 text-center">
                                <img src={"/img/header.svg"} className="header-presentation header-presentation--img" alt="" />
                            </div>
                        </div>
                    </div>
                </header>

                <section className="py-5 functions" id="moreinfo">
                    <div className="container">
                        <div className="row mt-5 hub-bottom">
                            <div className="col-xl-4 text-center">

                                <div className="icons text-primary mb-3">
                                    <img src={"/img/ic_home_1.svg"} alt="" />
                                </div>

                                <br />

                                <h2 className="font-weight-bold spacing">
                                    Rápido y sencillo
                                </h2>

                                <p align="left" className="font-size-md text-black-80 mb-6 spacing">
                                    Tecalis Signature hace que preparar y enviar documentos para firmar sea rápido y sencillo, aumentando la experiencia de uso a los clientes.
                                </p>
                            </div>

                            <div className="col-xl-4 text-center">

                                <div className="icons text-primary mb-3">
                                    <img src={"/img/ic_home_2.svg"} alt="" />
                                </div>

                                <br />

                                <h2 className="font-weight-bold spacing">
                                    Diseño vanguardista
                                </h2>

                                <p align="left" className="font-size-md text-black-80 mb-6 spacing">
                                    Tecalis Signature ofrece una experiencia de usuario excepcional gracias a nuestra apuesta por la innovación, aumentando así la satisfacción de
                                    los clientes.
                                </p>
                            </div>

                            <div className="col-xl-4 text-center">
                                <div className="icons text-primary mb-3">
                                    <img src={"/img/ic_home_3.svg"} alt="" />
                                </div>

                                <br />

                                <h2 className="font-weight-bold spacing">
                                    Plena validez legal
                                </h2>

                                <p align="left" className="font-size-md text-black-80 mb-6 spacing">
                                    Todos los datos que circulan a través de nuestra plataforma están encriptados y cifrados, cumpliendo con los estándares más rigurosos en
                                    seguridad.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="packs py-5 mb-6">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 order-md-2">

                                <div className="device-combo device-combo-macbook-iphonex mac-b">

                                    <div className="device device-macbook">
                                        <img src={"/img/signature.png"} className="device-screen ip-2" alt="..." />
                                        <img src={"/img/macbook.svg"} className="img-fluid" alt="..." />
                                    </div>

                                    <div className="device device-iphonex">
                                        <img src={"/img/signature-m.png"} className="device-screen ip-3" alt="..." />
                                        <img src={"/img/iphonex.svg"} className="img-fluid" alt="..." />
                                    </div>

                                </div>

                                <br /><br /><br />
                            </div>
                        </div>

                        <div className="mt-5" id="table--mobile">
                            <div className="pricing-table-wrapper">
                                <div className="position-absolute top-md-left text-warning mt-n9 m-md-n9 decor1">
                                    <img src={"/img/home_signature.svg"} alt="" />
                                </div>

                                <div className="row">
                                    <div className="col-12 col-xl">
                                        <div align="left">
                                            <h3 className="font-weight-bold spacing">100% garantía eIDAS</h3>
                                            <p className="spacing">
                                                Nuestra solución de firma electrónica cumple con las leyes de firma electrónica de la UE, las más avanzadas a nivel mundial.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-12 col-xl-auto">
                                        <img src={"/img/eidas.png"} className="img-fluid mb-2 mt-2" width="220" />
                                    </div>
                                </div>
                                <br />
                                <hr />
                                <br /><br />

                                <ul className="pricing-table">
                                    <li className="pricing-table__item hvr-float">
                                        <h3 align="center" className="pricing-table__title spacing">Estándar</h3>
                                        <p align="center" className="pricing-table__description">
                                            <span className="pricing-table__price spacing">25 €</span>
                                            <span className="pricing-table__label spacing"><b>por usuario/mes</b></span>
                                        </p>
                                        <ul className="pricing-table__products">
                                            <li className="pricing-table__product spacing">
                                                <Link to="/es/paquetes">
                                                    <Check className="icon mr-2 text-green" />75 operaciones LTV
                                                </Link>
                                            </li>
                                            <li className="pricing-table__product spacing">
                                                <Link to="/es/paquetes">
                                                    <Check className="icon mr-2 text-green" />Verificación mediante DNI
                                                </Link>
                                            </li>
                                        </ul>

                                        <Link to="/es/paquetes" className="btn btn-tecalis-sm-mobile-outline spacing img-fluid">Más información</Link>
                                    </li>

                                    <li className="pricing-table__item hvr-float">
                                        <h3 align="center" className="pricing-table__title">Premium</h3>

                                        <p align="center" className="pricing-table__description">
                                            <span className="pricing-table__price">45 €</span>
                                            <span className="pricing-table__label"><b>por usuario/mes</b></span>
                                        </p>

                                        <ul className="pricing-table__products">
                                            <li className="pricing-table__product spacing">
                                                <Link to="/es/paquetes">
                                                    <Check className="icon mr-2 text-green" />300 operaciones LTV
                                                </Link>
                                            </li>
                                            <li className="pricing-table__product spacing">
                                                <Link to="/es/paquetes">
                                                    <Check className="icon mr-2 text-green" />Verificación mediante DNI
                                                </Link>
                                            </li>
                                        </ul>
                                        <Link to="/es/paquetes" className="btn btn-tecalis-sm-mobile-outline spacing img-fluid">Más información</Link>
                                    </li>
                                    <li className="pricing-table__item bg-enterprise hvr-float">
                                        <h3 align="center" className="pricing-table__title text-white">Enterprise</h3>
                                        <p align="center" className="pricing-table__description">
                                            <span className="pricing-table__label spacing text-white"><b>Planes ajustados a las necesidades de tu empresa</b></span>
                                        </p>
                                        <br />
                                        <p className="spacing font-size-md text-white">Tecalis Signature dispone de planes personalizados que se ajustan a las
                                            necesidades concretas de las grandes
                                            compañías. Además, nuestra solución de firma electrónica puede integrarse con cualquier software (API), para que cualquier
                                            empresa tenga acceso a
                                            nuestra tecnología. sin tener que cambiar sus sistemas y procesos.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-5 ahorro">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5">
                                <h3 className="font-weight-bold spacing ">
                                    Maximiza la productividad de tu empresa
                                </h3>
                                <p className="font-size-md text-black-80 spacing mt-5 mb-4">
                                    <span className="font-weight-bold text-tecalis">Tecalis Signature</span> ayuda a reducir
                                    considerablemente el coste de las tareas administrativas, focalizando el tiempo y los recursos en las actividades principales de la empresa y en
                                    los
                                    clientes. Una firma tradicional tiene una serie de costes asociados (papel, impresora, escanear, enviar por email, recibir, imprimir, archivar
                                    en
                                    A-Z, etc) que lastran los resultados y la productividad de las empresas.
                                </p>
                                <p className="font-size-md text-black-80 spacing mb-6">
                                    Nuestra solución ofrece una amplia gama de herramientas y servicios que ayudan a facilitar el trabajo diario y la ventas, además de proporcionar
                                    una
                                    información instantánea de los acontecimientos que permite mejorar la atención y el seguimiento de los clientes. Todo esto hace que el coste
                                    marginal de utilizar Tecalis Signature sea mucho más económico que hacerlo de manera tradicional.
                                </p>
                            </div>

                            <div className="col-xl-1" />

                            <div className="col-xl-6">
                                <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8 decor4">
                                    <img src={"/img/home_signature_2.svg"} alt="" />
                                </div>

                                <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8 decor3">
                                    <img src={"/img/home_signature_2.svg"} alt="" />
                                </div>

                                <img src={"/img/productivity.jpg"} width="100%" className="img-fluid mt-2 mb-5 img-prod" alt="" />
                            </div>
                        </div>

                        <br />
                    </div>

                    <div className="svg-border-angled text-light">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" fill="#fff">
                            <polygon points="0,100 100,0 100,100" />
                        </svg>
                    </div>

                    <br /><br />
                </section>

                <section className="py-5 kyc">
                    <div className="container">
                        <div className="row">
                            <div align="center" className="col-xl-6">
                                <div className="device-combo device-combo-iphonex-iphonex mb-6 mb-md-0">
                                    <div className="device device-iphonex">
                                        <img src={"/img/identity-mock-m3.png"} className="device-screen" alt="..." />
                                        <img src={"/img/iphonex.svg"} className="img-fluid ip" alt="..." />
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <h3 className="font-weight-bold spacing ">
                                    KYC integrado
                                </h3>

                                <p className="font-size-md text-black-80 spacing mt-5 mb-4">
                                    <span className="font-weight-bold text-tecalis">Tecalis Signature</span> ofrece además un robusto proceso de reconocimiento biométrico, que
                                    destaca
                                    por su sorprendente velocidad e increíble sencillez, maximizando la experiencia de uso y la seguridad en el proceso de firma y validación de
                                    documentos.
                                </p>

                                <p className="font-size-md text-black-80 spacing mb-6">
                                    Este proceso está desarrollado con la última tecnología en encriptación de la información y tratamiento de datos, permitiendo así una
                                    autenticación
                                    robusta y confiable de los firmantes, constituyendo así una sólida prueba de conformidad para los documentos digitales y velando para que los
                                    datos
                                    volcados y registrados se encuentren seguros en todo momento, cumpliendo así los más estrictos estándares en protección de datos y de seguridad.
                                </p>

                                <img src={"/img/arrow.svg"} className="kyc-arrow" alt="" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-5 benefits">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-lg-9 text-center">

                                <div align="center">
                                    <br />
                                    <h5><span className="badge rounded-pill bg-info mt-3 py-2 pr-3 pl-3 spacing">Beneficios</span></h5>
                                    <br />
                                    <h3 align="center">Ahorro, tecnologías y control de facturas</h3>
                                    <br />
                                </div>

                                <div className="row mt-2 mb-5">
                                    <div className="col-xl-12">
                                        <div className="card hvr-float mb-3">
                                            <div className="card-body pt-2">
                                                <div className="row">
                                                    <div className="col-xl-2">
                                                        <img src={"/img/save.svg"} className="img-fluid mt-5" width="80" alt="..." />
                                                    </div>

                                                    <div className="col-xl-9">
                                                        <h4 align="left">Ahorro</h4>
                                                        <p align="left" className="text-black-80 spacing mb-4">
                                                            <span className="font-weight-bold text-tecalis">Tecalis Signature</span> ayuda a reducir considerablemente el coste de
                                                            las
                                                            tareas administrativas, focalizando el tiempo y los recursos en las actividades principales de la empresa y en los
                                                            clientes,
                                                            siendo mucho más barato que una firma tradicional.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-12">
                                        <div className="card hvr-float mb-3">
                                            <div className="card-body pt-2">
                                                <div className="row">
                                                    <div className="col-xl-2">
                                                        <img src="/img/tech.svg" className="img-fluid mt-3" width="80" alt="..." />
                                                    </div>

                                                    <div className="col-xl-9">
                                                        <h4 align="left">Tecnologías</h4>
                                                        <p align="left" className="text-black-80 spacing mb-4">
                                                            <span className="font-weight-bold text-tecalis">Tecalis Signature</span> utiliza las últimas tecnologías en encriptación
                                                            de
                                                            la
                                                            información y tratamiento de datos, permitiendo así una autenticación robusta y confiable de los firmantes.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-12">
                                        <div className="card hvr-float mb-3">
                                            <div className="card-body pt-2">
                                                <div className="row">
                                                    <div className="col-xl-2">
                                                        <img src={"/img/bill.svg"} className="img-fluid mt-5" width="80" alt="..." />
                                                    </div>
                                                    <div className="col-xl-9">
                                                        <h4 align="left">Control de facturas</h4>
                                                        <p align="left" className="text-black-80 spacing mb-4">
                                                            Gracias a nuestros paquetes cerrados, controlarás fácilmente el gasto en nuestros servicios. Además, si necesitas más
                                                            operaciones, te ofrecemos paquetes adicionales para tener los costes en todo momento controlados.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div align="center" className="img-benefits"><img src={"/img/benefits.svg"} className="img-fluid" alt="..." /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-5 links">
                    <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8 decor">
                        <img src={"/img/home_signature_3.svg"} alt="" />
                    </div>

                    <div className="container">
                        <div className="links-wrapper">
                            <div className="row links-card">
                                <div className="col-xl-6">
                                    <div className="card hvr-float mb-3">
                                        <div className="card-body">
                                            <div align="center">
                                                <img src={"/img/w-paper.svg"} className="img-fluid mt-3 mr-4 mb-3" width="290" alt="..." />
                                            </div>
                                            <p className="font-size-md text-black-80 spacing mt-5 mb-4 pl-5 pr-5">
                                                Conoce las oportunidades y las ventajas que ofrece la firma electrónica ante los diferentes retos del nuevo entorno profesional. 😎
                                            </p>
                                            <p>
                                                <a href="" data-toggle="modal" data-target="#wpaper" className="link-tecalis pl-5 pr-5 spacing font-weight-bold">
                                                    Descargar White Paper 👉
                                                </a>
                                            </p>
                                            <br />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-6">
                                    <div className="card hvr-float mb-3">
                                        <div className="card-body">
                                            <div align="center">
                                                <img src={"/img/functions.svg"} className="img-fluid mt-3 mb-3" width="426" alt="..." />
                                            </div>

                                            <p className="font-size-md text-black-80 spacing mt-5 mb-4 pl-5 pr-5">
                                                Descubre las diferentes funcionalidades que ofrece nuestra solución de firma electrónica para ayudar a las empresas a completar su
                                                transformación digital.
                                            </p>

                                            <p>
                                                <Link to="/es/funcionalidades" className="link-tecalis pl-5 pr-5 spacing font-weight-bold">Ver funcionalidades 👉</Link>
                                            </p>

                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default IndexPage
